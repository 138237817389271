<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <c-table
        ref="grid1"
        title="작업관련 서비스"
        :columns="grid1.columns"
        :data="grid1.data"
        :gridHeight="grid1.height"
        :editable="editable"
        :hideBottom="true"
        :usePaging="false"
        :isExcelDown="false"
        :filtering="false"
        :isFullScreen="true"
        :columnSetting="false"
        topBorderClass="topcolor-orange"
        selection="multiple"
        rowKey="mdmSwsVendorCd"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="추가" icon="add" @btnClicked="addRow1" v-if="editable && Boolean(param.mdmSwsId)" />
            <c-btn label="삭제" icon="remove" @btnClicked="removeRow1" v-if="editable && Boolean(param.mdmSwsId)" />
            <c-btn label="저장" icon="save" @btnClicked="saveRow1" v-if="editable && Boolean(param.mdmSwsId)" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <c-table
        ref="grid2"
        title="작업관련 공구"
        :columns="grid2.columns"
        :data="grid2.data"
        :gridHeight="grid2.height"
        :editable="editable"
        :hideBottom="true"
        :usePaging="false"
        :isExcelDown="false"
        :filtering="false"
        :isFullScreen="true"
        :columnSetting="false"
        topBorderClass="topcolor-orange"
        selection="multiple"
        rowKey="mdmSwsToolId"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="추가" icon="add" @btnClicked="addRow2" v-if="editable && Boolean(param.mdmSwsId)" />
            <c-btn label="삭제" icon="remove" @btnClicked="removeRow2" v-if="editable && Boolean(param.mdmSwsId)" />
            <c-btn label="저장" icon="save" @btnClicked="saveRow2" v-if="editable && Boolean(param.mdmSwsId)" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
      <c-table
        ref="grid3"
        title="작업자"
        :columns="grid3.columns"
        :data="grid3.data"
        :gridHeight="grid3.height"
        :editable="editable"
        :hideBottom="true"
        :usePaging="false"
        :isExcelDown="false"
        :filtering="false"
        :isFullScreen="true"
        :columnSetting="false"
        topBorderClass="topcolor-orange"
        selection="multiple"
        rowKey="mdmSwsWorkerId"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="추가" icon="add" @btnClicked="addRow3" v-if="editable && Boolean(param.mdmSwsId)" />
            <c-btn label="삭제" icon="remove" @btnClicked="removeRow3" v-if="editable && Boolean(param.mdmSwsId)" />
            <c-btn label="저장" icon="save" @btnClicked="saveRow3" v-if="editable && Boolean(param.mdmSwsId)" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-standard-detail-work',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmSwsId: '',
        plantCd: '',
        updateFlag: '',
        isRevision: false,
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      editable: false,
      grid1: {
        columns: [
          {
            name: 'vendorName',
            field: 'vendorName',
            label: '용역업체 명',
            align: 'center',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'workContents',
            field: 'workContents',
            label: '작업 내용',
            align: 'left',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
        height: '300px',
      },
      grid2: {
        columns: [
          {
            name: 'toolName',
            field: 'toolName',
            label: '공구명',
            align: 'center',
            type: 'text',
            required: true,
            sortable: false,
          },
          {
            name: 'toolCnt',
            field: 'toolCnt',
            label: '예상 사용개수',
            type: 'number',
            style: 'width:150px',
            sortable: false,
          },
        ],
        data: [],
        height: '300px',
      },
      grid3: {
        columns: [],
        data: [],
        height: '300px',
      },
      listUrl1: '',
      saveUrl1: '',
      deleteUrl1: '',
      listUrl2: '',
      saveUrl2: '',
      deleteUrl2: '',
      listUrl3: '',
      saveUrl3: '',
      deleteUrl3: '',
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'param.updateFlag'() {
      this.getList1(this.param.updateFlag.split('|')[0])
      this.getList2(this.param.updateFlag.split('|')[0])
      this.getList3(this.param.updateFlag.split('|')[0])
      if (this.param.updateFlag.split('|')[1] == 'true') {
        this.editable = false;
      } else {
        this.editable = this.$route.meta.editable;
      }
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl1 = selectConfig.mdm.sws.service.list.url;
      this.saveUrl1 = transactionConfig.mdm.sws.service.save.url;
      this.deleteUrl1 = transactionConfig.mdm.sws.service.delete.url;
      this.listUrl2 = selectConfig.mdm.sws.tool.list.url;
      this.saveUrl2 = transactionConfig.mdm.sws.tool.save.url;
      this.deleteUrl2 = transactionConfig.mdm.sws.tool.delete.url;
      this.listUrl3 = selectConfig.mdm.sws.worker.list.url;
      this.saveUrl3 = transactionConfig.mdm.sws.worker.save.url;
      this.deleteUrl3 = transactionConfig.mdm.sws.worker.delete.url;


      this.$comm.getComboItems('JOB_KIND_CD').then(_result1 => {
        this.$comm.getComboItems('JOB_KIND_GRADE_CD').then(_result2 => {
          this.grid3.columns = [
            {
              name: 'jobKindCd',
              field: 'jobKindCd',
              label: '직종',
              align: 'center',
              type: 'select',
              comboItems: _result1,
              sortable: true,
              required: true,
            },
            {
              name: 'jobKindGradeCd',
              field: 'jobKindGradeCd',
              label: '등급',
              align: 'center',
              type: 'select',
              comboItems: _result2,
              sortable: true,
            },
            {
              name: 'termTm',
              field: 'termTm',
              label: '예상소요시간',
              type: 'datetime',
              minuteStep: 10,
              style: 'width:150px',
              sortable: true,
            },
          ]
          
          this.getList1(this.param.mdmSwsId);
          this.getList2(this.param.mdmSwsId);
          this.getList3(this.param.mdmSwsId);
        });
      });

    },
    getList1(_mdmSwsId) {
      if (_mdmSwsId) {
        this.$http.url = this.listUrl1;
        this.$http.param = {mdmSwsId: _mdmSwsId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid1.data = _result.data;
        },);
      }
    },
    getList2(_mdmSwsId) {
      if (_mdmSwsId) {
        this.$http.url = this.listUrl2;
        this.$http.param = {mdmSwsId: _mdmSwsId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid2.data = _result.data;
        },);
      }
    },
    getList3(_mdmSwsId) {
      if (_mdmSwsId) {
        this.$http.url = this.listUrl3;
        this.$http.param = {mdmSwsId: _mdmSwsId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid3.data = _result.data;
        },);
      }
    },
    addRow1() {
      this.popupOptions.title = '용역업체 검색'; // 설비 검색
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.param.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/vendor/vendorPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipPopup;
    },
    closeEquipPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        let saveData = [];
        this.$_.forEach(data, (item) => {
          if ( this.$_.findIndex(this.grid1.data, {mdmSwsVendorCd: item.vendorCd}) === -1 ) {
            saveData.push({
              mdmSwsId: this.param.mdmSwsId,  // 안전작업 표준 일련번호
              mdmSwsVendorCd: item.vendorCd,  // 작업관련 설비
              editFlag: 'C',
              regUserId: this.$store.getters.user.userId,
            });
          }
        });
        
        this.$http.url = this.saveUrl1;
        this.$http.type = 'POST';
        this.$http.param = saveData;
        this.$http.request(() => {
          this.getList1(this.param.mdmSwsId);
          window.getApp.$emit('ALERT', {
            title: '안내' /* 안내 */,
            message: '저장되었습니다.' ,
            type: 'success', // success / info / warning / error
          });
        });
      }
    },
    saveRow1() {
      let saveData = this.grid1.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.saveUrl1;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getList1(this.param.mdmSwsId);
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '변경된 데이터가 없습니다.',
          type: 'info', // success / info / warning / error
        });
      }
    },
    removeRow1() {
      let selectData = this.$refs['grid1'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl1);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid1.data = this.$_.reject(this.grid1.data, item);
              })
              this.$refs['grid1'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addRow2() {
      this.grid2.data.push({
        mdmSwsId: this.param.mdmSwsId,  // 안전작업 표준 일련번호
        mdmSwsToolId: uid(),  // 작업공구 일련번호
        toolName: '',  // 공구명
        toolCnt: '',  // 예상 사용개수
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId,
      });
    },
    saveRow2() {
      if (this.$comm.validTable(this.grid2.columns, this.grid2.data)) {
        let saveData = this.grid2.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl2;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getList2(this.param.mdmSwsId);
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeRow2() {
      let selectData = this.$refs['grid2'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl2);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, item);
              })
              this.$refs['grid2'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addRow3() {
      this.grid3.data.push({
        mdmSwsId: this.param.mdmSwsId,  // 안전작업 표준 일련번호
        mdmSwsWorkerId: uid(),  // 작업안전표준 작업자 일련번호
        jobKindCd: null,  // 직종
        jobKindGradeCd: null,  // 직종등급
        termTm: '',  // 예상 소요시간
        editFlag: 'C',
        regUserId: this.$store.getters.user.userId,
      });
    },
    saveRow3() {
      if (this.$comm.validTable(this.grid3.columns, this.grid3.data)) {
        let saveData = this.grid3.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl3;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getList3(this.param.mdmSwsId);
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', 
            message: '변경된 데이터가 없습니다.',
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    removeRow3() {
      let selectData = this.$refs['grid3'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl3);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid3.data = this.$_.reject(this.grid3.data, item);
              })
              this.$refs['grid3'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>